<template>
  <div class="pa-2 my-2">
    <div class="d-flex full-width">
      <div class="flex-2">
        <h2 class="ml-4">{{ $t("HI") }}</h2>
        <apexchart type="line" :options="chartOptions" :series="series" :height="600"></apexchart>
      </div>
      <div class="flex-1 ml-4">
        <h2 class="mb-2">{{ $t("Works") }}</h2>
        <div class="vertical-table-container">
          <div class="vertical-cell-container">
            <div class="header" :style="{ width: '100px' }">{{ $t("Year") }}</div>
            <div class="header" :style="{ width: '150px' }">{{ $t("Repair type") }}</div>
            <div class="header" :style="{ width: '150px' }">{{ $t("Cost") }}</div>
          </div>
          <div class="vertical-cell-container" v-for="(work, idx) in works" :key="idx">
            <div class="header" :style="{ width: '100px' }">
              {{ work.year }}
            </div>
            <div class="cell" :style="{ width: '150px' }">
              {{ work[sysCodeLanguage] }}
            </div>
            <div class="cell" :style="{ width: '150px' }">
              {{ work.total_work_cost | formatNumber }}
            </div>
          </div>
          <div class="vertical-cell-container" v-if="works.length === 0">
            <div class="header" :style="{ width: '100px' }">-</div>
            <div class="cell" :style="{ width: '150px' }">-</div>
            <div class="cell" :style="{ width: '150px' }">-</div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mb-2 ml-4">{{ $t("HI") }}</h2>
    <div class="table-container ml-4">
      <div class="cell-container" v-for="(element, idx) in rawSeries" :key="idx">
        <div class="header">{{ element.year }}</div>
        <div class="cell">{{ element.hi | formatNumber }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BridgeService from "@/services/BridgeService";
import { formatNumber, getSeriesColors } from "@/helpers";

export default {
  name: "SoundnessVisualization",
  filters: {
    formatNumber,
  },
  data() {
    return {
      rawSeries: [],
      works: [],
    };
  },
  computed: {
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    numberOfYears() {
      if (this.rawSeries.length) {
        return (
          this.rawSeries[this.rawSeries.length - 1].year -
          this.rawSeries[0].year +
          1
        );
      }
      return 0;
    },
    data() {
      return this.years.map((year) => {
        const s = this.rawSeries.find((x) => x.year === year);
        return {
          year,
          hi: s ? s.hi : null,
        };
      });
    },
    years() {
      if (this.rawSeries.length) {
        const numberOfYears =
          this.rawSeries[this.rawSeries.length - 1].year -
          this.rawSeries[0].year +
          1;
        return new Array(numberOfYears)
          .fill(1)
          .map((x, idx) => idx + this.rawSeries[0].year);
      }
      return [];
    },
    chartOptions() {
      const categories = this.years;
      return {
        animations: {
          enabled: false,
        },
        xaxis: {
          categories,
          labels: {
            formatter: function (value) {
              if (value % 5 === 0) {
                return value;
              }
              return "";
            },
          },
        },
        tooltip: {
          x: {
            formatter: function (value, { dataPointIndex }) {
              return categories[dataPointIndex];
            },
          },
        },
        markers: {
          size: 3,
        },
        yaxis: [
          {
            min: 0,
            max: 100,
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
          },
        ],
      };
    },
    series() {
      return [
        {
          data: this.data.map((x) => x.hi),
          name: this.$t("HI"),
          color: getSeriesColors("HI"),
        },
        ...this.selectedScenario.SysLccScenarioWorks.map((s) => ({
          name: s.SysLccThreshold[this.sysCodeLanguage],
          data: this.data.map((x) => s.SysLccThreshold.hi),
          color: getSeriesColors(s.SysLccThreshold.repair_level),
        })).filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i),
      ];
    },
  },
  props: [
    "selectedScenario",
    "bridgeId",
    "selectedSpanNumber",
    "selectedComponent",
    "selectedElement",
    "granularity",
  ],
  watch: {
    selectedScenario: {
      handler: function () {
        this.getSoundnessSeries();
        this.getWorks();
      },
      immediate: true,
    },
    bridgeId: {
      handler: function () {
        if (this.granularity === "bridge") {
          this.getSoundnessSeries();
          this.getWorks();
        }
      },
      immediate: true,
    },
    selectedSpanNumber: {
      handler: function () {
        if (this.granularity === "span") {
          this.getSoundnessSeries();
          this.getWorks();
        }
      },
      immediate: false,
    },
    selectedComponent: {
      handler: function () {
        if (["component"].includes(this.granularity)) {
          this.getSoundnessSeries();
          this.getWorks();
        }
      },
      immediate: false,
    },
    selectedElement: {
      handler: function () {
        if (["element"].includes(this.granularity)) {
          this.getSoundnessSeries();
          this.getWorks();
        }
      },
      immediate: false,
    },
  },
  methods: {
    async getWorks() {
      try {
        if (!this.bridgeId || this.selectedScenario.scenario_number == null) {
          return;
        }

        this.works = [];
        this.works = (
          await BridgeService.getWorkList(
            this.selectedScenario.scenario_number,
            this.bridgeId,
            this.selectedSpanNumber,
            this.selectedComponent,
            this.selectedElement
          )
        ).data;
      } catch (e) {
        console.log(e);
      }
    },
    async getSoundnessSeries() {
      try {
        if (!this.bridgeId || this.selectedScenario.scenario_number == null) {
          return;
        }
        this.rawSeries = [];
        this.rawSeries = (
          await BridgeService.getSoundnessSeries(
            this.selectedScenario.scenario_number,
            this.bridgeId,
            this.selectedSpanNumber,
            this.selectedComponent,
            this.selectedElement
          )
        ).data;
      } catch (e) {
        console.log(e);
        this.series = [
          {
            data: [],
          },
        ];

        this.rawSeries = [];
      }
    },
  },
};
</script>
