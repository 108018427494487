var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bridge-toolbar d-flex align-center elevation-1 pa-4"},[_c('div',[_c('span',{staticClass:"text-h6 mr-4",style:({ minWidth: '250px' })},[_vm._v(" "+_vm._s(_vm.$t("Bridge"))+":"+_vm._s(_vm.bridge.roadnumber)+" | "+_vm._s(_vm.bridge.chainage)+" | "+_vm._s(_vm.bridge.bridge_name))])]),(_vm.bridge.id)?_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
      name: 'bridge-inventory',
      params: { id: _vm.bridge.id, bridge: _vm.bridge },
    }}},[_vm._v(_vm._s(_vm.$t("inventory1")))]),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
      name: 'bridge-gallery',
      params: { id: _vm.bridge.id },
    }}},[_vm._v(_vm._s(_vm.$t("gallery")))]),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
      name: 'bridge-files',
      params: { id: _vm.bridge.id },
    }}},[_vm._v(_vm._s(_vm.$t("files")))]),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
      name: 'bridge-inspection',
      params: { id: _vm.bridge.id, bridge: _vm.bridge },
    }}},[_vm._v(_vm._s(_vm.$t("inspection")))]),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
      name: 'bridge-repair-history',
      params: { id: _vm.bridge.id, bridge: _vm.bridge },
    }}},[_vm._v(_vm._s(_vm.$t("repair-history")))]),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
      name: 'bridge-soundness',
      params: { id: _vm.bridge.id, bridge: _vm.bridge },
    }}},[_vm._v(_vm._s(_vm.$t("soundness")))])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }