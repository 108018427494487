<template>
  <ImagesGallery :images="bridge.BridgePhotos" :parent-id="bridge.id" file-type="bridge_photo"
    @update="$emit('updateBridge')" :edit="true"/>
</template>



<script>
import ImagesGallery from "@/components/main/ImagesGallery.vue";

export default {
  name: "BridgeInventoryGallery",
  components: {
    ImagesGallery,
  },
  props: ["bridge"],
};
</script>
<style scoped></style>