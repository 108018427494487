<template>
  <ImagesGallery
    :images="bridge.BridgeDrawings"
    :parent-id="bridge.id"
    file-type="bridge_drawing"
    :edit="true"
    @update="$emit('updateBridge')"
  />
</template>



<script>
import ImagesGallery from "@/components/main/ImagesGallery.vue";

export default {
  name: "BridgeInventoryFiles",
  components: {
    ImagesGallery,
  },
  props: ["bridge"],
};
</script>
<style scoped>
</style>