<template>
  <v-container class="py-10 px-6" fluid>
    <v-row>
      <v-col class="text-left">
        <v-btn v-if="$store.getters.get_access('module_bridge_management', 3) && bridge.id" class="ma-2 blue--text" small
          outlined color="blue" :to="{
            name: 'bridge-edit-identification-data',
            params: { id: bridge.id, bridge: bridge },
          }">{{ $t('Edit bridge identification data') }}</v-btn>

      </v-col>
      <v-col class="text-right">
        <v-btn v-if="$store.getters.get_access('module_bridge_management', 2) && bridge.id" class="ma-2 blue--text" small
          outlined color="blue" :to="{
            name: 'bridge-inventory-edit',
            params: { id: bridge.id, bridge: bridge },
          }">{{$t("Edit Bridge Details")}}</v-btn>
        <v-btn icon @click="exportExcel()"><v-icon color="blue" medium class="mr-2">
            mdi-download
          </v-icon></v-btn>
      </v-col>
    </v-row>

    <v-card class="mb-4 pa-2" v-for="section in sections.slice(0, 1)" :key="section.key">
      <h3 class="mb-2">
        {{ bridgeDataConfig[section.key].title.label }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr v-for="prop in getFirstColumnProperties(section.key, section.split)" :key="prop.key">
              <td>{{ prop.label }}</td>
              <td>{{ formattedBridge[prop.key] }}</td>
            </tr>
          </table>
        </v-col>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <div id="inventory_map"></div>
        </v-col>

      </v-row>
    </v-card>

    <v-card class="mb-4 pa-2" v-for="section in sections.slice(1, 3)" :key="section.key">
      <h3 class="mb-2">
        {{ bridgeDataConfig[section.key].title.label }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr v-for="prop in getFirstColumnProperties(section.key, section.split)" :key="prop.key">
              <td>{{ prop.label }}</td>
              <td>{{ formattedBridge[prop.key] }}</td>
            </tr>
          </table>
        </v-col>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr v-for="prop in getSecondColumnProperties(section.key, section.split)" :key="prop.key">
              <td>{{ prop.label }}</td>
              <td>{{ formattedBridge[prop.key] }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card>


    <v-card class="mb-4 pa-2">
      <h3 class="mb-2">
        {{ $t("substructure") }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr v-for="prop in bridgeDataConfig.substructure.properties.slice(0, 8)" :key="prop.key">
              <td>{{ prop.label }}</td>
              <td>{{ formattedBridge[prop.key] }}</td>
            </tr>
          </table>
        </v-col>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table v-if="formattedBridge.nb_of_spans > 1">
            <tr>
              <td>{{ $t("nb_of_pier") }}</td>
              <td>{{ formattedBridge.nb_of_pier }}</td>
            </tr>
            <tr>
              <td>{{ $t("pier_structure") }}</td>
              <td>{{ displayDetailIfExists("pier_structure") }}</td>
            </tr>
            <tr>
              <td>{{ $t("pier_shape") }}</td>
              <td>
                {{ displayDetailIfExists("pier_shape") }}
              </td>
            </tr>
            <tr v-if="bridge.pier_shape === 'CIRCL'">
              <td>{{ $t("pier_diameter") }}</td>
              <td>
                {{ formattedBridge.pier_diameter }}
              </td>
            </tr>
            <tr v-if="bridge.pier_shape === 'RECTA'">
              <td>{{ $t("pier_length") }}</td>
              <td>
                {{ formattedBridge.pier_length }}
              </td>
            </tr>
            <tr v-if="bridge.pier_shape === 'RECTA'">
              <td>{{ $t("pier_breadth") }}</td>
              <td>
                {{ formattedBridge.pier_breadth }}
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mb-4 pa-2" v-for="section in sections.slice(4, 6)" :key="section.key">
      <h3 class="mb-2">
        {{ bridgeDataConfig[section.key].title.label }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr v-for="prop in getFirstColumnProperties(section.key, section.split)" :key="prop.key">
              <td>{{ prop.label }}</td>
              <td>{{ formattedBridge[prop.key] }}</td>
            </tr>
          </table>
        </v-col>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr v-for="prop in getSecondColumnProperties(section.key, section.split)" :key="prop.key">
              <td>{{ prop.label }}</td>
              <td>{{ formattedBridge[prop.key] }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mb-4 pa-2">
      <h3 class="mb-2">
        {{ $t("data_information") }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr>
              <td>{{ $t("inventory_data_status") }}</td>
              <td>
                {{ formattedBridge.InventoryDataStatus ? formattedBridge.InventoryDataStatus[description] : '' }}
              </td>
            </tr>
          </table>
        </v-col>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr>
              <td>{{ $t("latest_update_date") }}</td>
              <td>{{ formattedBridge.latest_update_date }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="start" cols="6" sm="4" md="6">
          <table>
            <tr>
              <td>{{ $t("latest_update_user") }}</td>
              <td>{{ formattedBridge.latest_update_user }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-4 pa-2">
      <h3 class="mb-2">
        {{ $t("notes") }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="12">
          <table>
            <tr>
              <td>{{ bridge.note }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-4 pa-2" v-if="formattedBridge.displayInspection">
      <h3 class="mb-2">
        {{ $t("Span information from last inspection") }}
      </h3>
      <v-row>
        <v-col align-self="start" cols="12">
          <table>
            <tr>
              <td>{{ $t("inspection-date") }}</td>
              <td>{{ formattedBridge.Inspections[0].inspection_date }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row v-for="span in formattedBridge.formattedInspection.InspectionSpans" :key="span.span_number">
        <v-col align-self="start" cols="12">
          <table>
            <tr>
              <td>{{ $t("span_number") }}</td>
              <td>{{ span.span_number }}</td>
            </tr>
            <tr>
              <td>{{ $t("span_length") }}</td>
              <td>{{ span.span_length }}</td>
            </tr>
            <tr>
              <td>{{ $t("inspectable") }}</td>
              <td>{{ span.inspectable }}</td>
            </tr>
            <tr v-for="element in span.formattedElements" :key="element.id">
              <td>{{ displayComponents(element.component) }}</td>
              <td>{{ displayElements(element.element) }}</td>
              <td>{{ displayCode('element_damage', 'element_material', element.element_material) }}</td>
            </tr>

          </table>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-4 pa-2 red" v-if="$store.getters.get_access('module_bridge_management', 3)">
      <v-card-title class="justify-center">
        <h3>
          {{ $t("delete-bridge") }} : {{ bridge.roadnumber }} | {{ bridge.chainage }} | {{
            displayDetailIfExists("direction") }} |
          {{ bridge.bridge_name }}
        </h3>
      </v-card-title>
      <v-card-text class="text-center">
        <v-btn v-if="$store.getters.get_access('module_bridge_management', 3)" class="ma-2 black--text " small outlined
          color="black" @click="toggleModal">
          {{ $t("delete-bridge") }}
        </v-btn>
      </v-card-text>
    </v-card>
    <AreYouSureModal resource="bridge" prefix="bridge" :id="id" :dialog="deleteDialog" @toggle="toggleModal"
      @deleted="deletedBridge" />
  </v-container>
</template>



<script>
import AreYouSureModal from '@/components/main/AreYouSureModal.vue';
import * as XLSX from 'xlsx';
import mapboxgl from "mapbox-gl";

export default {
  name: "bridge-inventory",
  props: ["bridge", "bridgeDataConfig", "sections"],
  components: {
    AreYouSureModal,
  },
  data() {
    return {
      deleteDialog: false,
      deleted: false,
    };
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

    const map = new mapboxgl.Map({
      container: "inventory_map",
      style: "mapbox://styles/mapbox/light-v10",
      center: [104, 18.4],
      zoom: 5,
    });

    this.mapPromise = new Promise((resolve) => {
      map.on("load", () => resolve(map));
    });

    
  },
  watch: {
    bridge: {
      handler() {
        if(this.bridge) {
          this.setBridgeLocation();
        }
      }
    }
  },
  computed: {
    fileName() {
      if (!this.bridge.chainage) {
        return '';
      }
      return `${this.bridge.roadnumber}${this.bridge.chainage.replace('+', '-')}${this.bridge.direction}.xlsx`;
    },
    id() {
      return this.$route.params.id;
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    formattedBridge() {
      const bridge = {};
      this.sections.forEach(section => {
        this.bridgeDataConfig[section.key].properties.forEach(prop => {
          if (prop.type === 'raw') {
            bridge[prop.key] = this.bridge[prop.key];
          }
          if (prop.type === 'syscode') {
            bridge[prop.key] = this.displayDetailIfExists(prop.key);
          }
          if (prop.type === 'region') {
            bridge[prop.key] = this.getRegionName(this.bridge[prop.key], prop.key);
          }
          if (prop.type === 'yesno') {
            bridge[prop.key] = this.formatToYesNo(this.bridge[prop.key]);
          }
        });
      });
      bridge.displayInspection = false;
      bridge.formattedInspection = {}
      bridge.road_class = this.bridge.Road.class
      if (this.bridge.Inspections) {
        if (this.bridge.Inspections.length) {

          bridge.displayInspection = true;
          bridge.formattedInspection.InspectionSpans = this.bridge.Inspections[0].InspectionSpans;


          bridge.formattedInspection.InspectionSpans.forEach((span, idx) => {
            bridge.formattedInspection.InspectionSpans[idx].formattedElements = [];
            const element_list = new Set();
            span.ElementDamages.forEach(damage => {
              if (!element_list.has(damage.element)) {
                bridge.formattedInspection.InspectionSpans[idx].formattedElements.push(damage)
                element_list.add(damage.element)
              }
            })
          })


        }
      }

      return {
        ...this.bridge,
        ...bridge,
      };
    }
  },
  methods: {
    setBridgeLocation() {
      this.mapPromise.then((map) => {
        this.marker = new mapboxgl.Marker()
          .setLngLat([this.bridge.bridge_gps_x, this.bridge.bridge_gps_y])
          .addTo(map);
      })
    },
    formatToYesNo(data) {
      return this.$i18n.locale === "la"
        ? data
          ? "ມີ"
          : "ບໍ່​ມີ"
        : data
          ? "Yes"
          : "No";
    },
    getRegionName(region, type) {
      if (!region) {
        return '';
      }
      const listName = type === 'bridge_province' ? 'provincesList' : 'districtList';
      const data = this.$store.state.adm[listName].find(
        (x) => x.id === region
      );
      if (data) {
        return data[this.description];
      }
      return region;
    },
    displayDetailIfExists(x_attribute) {
      if (!this.bridge[x_attribute]) {
        return '';
      }
      const sysCode = this.$store.state.sysCode.sysCodes.filter((x) => x.tbl_name == 'bridge' && x.fld_name === x_attribute && x.code === this.bridge[x_attribute]);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return this.bridge[x_attribute];
    },
    displayCode(table, fld_name, code) {
      const sysCode = this.$store.state.sysCode.sysCodes.filter((x) => x.tbl_name == table && x.fld_name === fld_name && x.code === code);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return code;
    },
    displayComponents(code) {
      const sysCode = this.$store.state.sysCode.sysComponents.filter((x) => x.code === code);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return code;
    },
    displayElements(code) {
      const sysCode = this.$store.state.sysCode.sysElements.filter((x) => x.code === code);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return code;
    },
    toggleModal() {
      this.deleteDialog = !this.deleteDialog;
    },
    deletedBridge(isDeleted) {
      if (isDeleted) {
        this.$router.push({ name: "bridge-management-list" });
      }
    },
    getFirstColumnProperties(section, split) {
      return this.bridgeDataConfig[section].properties.slice(0, split);
    },
    getSecondColumnProperties(section, split) {
      return this.bridgeDataConfig[section].properties.slice(split);
    },
    exportExcel() {
      const wb = XLSX.utils.book_new();
      this.sections.forEach(sheet => {
        const sheetName = this.bridgeDataConfig[sheet.key].title.label;
        wb.SheetNames.push(sheetName);
        const ws_data = [];
        ws_data.push(this.bridgeDataConfig[sheet.key].properties.map(prop => prop.label));
        ws_data.push(this.bridgeDataConfig[sheet.key].properties.map(prop => this.formattedBridge[prop.key]));

        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets[sheetName] = ws;
      });

      XLSX.writeFile(wb, this.fileName);

    }
  },
};
</script>

<style scoped>
table {
  background-color: #f0e6e6;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid rgb(227, 233, 233);
  width: 100%;
}

td,
th {
  width: 80px;
  border: 1px solid rgb(188, 194, 194);
  background-color: rgba(227, 233, 233);
  text-align: left;
  padding: 4px;
}

tr:nth-child(even) {
  background-color: #f7f5f5;
}

#inventory_map {
  height: 400px;
}
</style>