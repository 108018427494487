<template>
  <div class="d-flex flex-column">
    <BridgeDetailMenu :bridge="bridge"></BridgeDetailMenu>
    <router-view :bridge="bridge" :bridge-data-config="bridgeDataConfig" :sections="sections" @updateBridge="loadBridge">
    </router-view>
  </div>
</template>


<script>
import BridgeDetailMenu from "@/components/bridgeManagement/bridge/BridgeDetailMenu.vue";
import BridgeService from "@/services/BridgeService";
import router from '@/router';

export default {
  name: "bridge",
  components: {
    BridgeDetailMenu,
  },
  data() {
    return {
      bridge: {},
      bridgeid: null,
      sections: [
        { key: 'identification', split: 5 },
        { key: 'general', split: 16 },
        { key: 'superstructure', split: 3 },
        { key: 'substructure', split: 8 },
        { key: 'bearings', split: 3 },
        { key: 'bridge_facilities', split: 15 },
        { key: 'data_information', split: 2 },
      ],
      bridgeDataConfigBase: {
        identification: {
          properties: [
            { key: 'roadnumber', type: 'raw' },
            { key: 'chainage', type: 'raw' },
            { key: 'direction', type: 'syscode' },
            { key: 'linkid', type: 'raw' },
            { key: 'road_class', type: 'raw' },
          ]
        },
        general: {
          properties: [
            { key: 'bridge_name', type: 'raw' },
            { key: 'river_name', type: 'raw' },
            { key: 'bridge_province', type: 'region' },
            { key: 'bridge_district', type: 'region' },
            { key: 'bridge_gps_x', type: 'raw' },
            { key: 'bridge_gps_y', type: 'raw' },
            { key: 'bridge_gps_z', type: 'raw' },
            { key: 'bridge_status', type: 'syscode' },
            { key: 'bridge_status_comments', type: 'raw' },
            { key: 'bridge_crossing', type: 'syscode' },
            { key: 'bridge_crossing_specify', type: 'raw' },
            { key: 'nb_of_spans', type: 'raw' },
            { key: 'year_of_construction', type: 'raw' },
            { key: 'latest_repair_year', type: 'raw' },
            { key: 'source_of_funding', type: 'syscode' },
            { key: 'source_of_funding_specify', type: 'raw' },
            { key: 'design_standard', type: 'syscode' },
            { key: 'design_standard_specify', type: 'raw' },
            { key: 'max_load', type: 'raw' },
            { key: 'bridge_material', type: 'syscode' },
            { key: 'bridge_material_specify', type: 'raw' },
            { key: 'bridge_structure', type: 'syscode' },
            { key: 'bridge_structure_specify', type: 'raw' },
            { key: 'bridge_length', type: 'raw' },
            { key: 'max_span_length', type: 'raw' },
            { key: 'min_span_length', type: 'raw' },
            { key: 'bridge_width', type: 'raw' },
            { key: 'width_of_carriageway', type: 'raw' },
            { key: 'width_of_inbound_lane', type: 'raw' },
            { key: 'width_of_outbound_lane', type: 'raw' },
            { key: 'bridge_freeboard', type: 'raw' },
          ]
        },
        superstructure: {
          properties: [
            { key: 'superstructure_type', type: 'syscode' },
            { key: 'superstructure_type_specify', type: 'raw' },
            { key: 'main_girder_material_type', type: 'syscode' },
            { key: 'nb_of_main_girders', type: 'raw' },
            { key: 'deck_slab_material_type', type: 'syscode' },
            { key: 'superstructure_other_members_material_type', type: 'syscode' },
          ]
        },
        substructure: {
          properties: [
            { key: 'substructure_height', type: 'raw' },
            { key: 'abutment_type', type: 'syscode' },
            { key: 'abutment_type_specify', type: 'raw' },
            { key: 'foundation_type', type: 'syscode' },
            { key: 'foundation_type_specify', type: 'raw' },
            { key: 'pier_number_of_columns', type: 'raw' },
            { key: 'foundation_material_type', type: 'syscode' },
            { key: 'foundation_material_type_specify', type: 'raw' },
            { key: 'nb_of_pier', type: 'raw' },
            { key: 'pier_structure', type: 'syscode' },
            { key: 'pier_shape', type: 'syscode' },
            { key: 'pier_diameter', type: 'raw' },
            { key: 'pier_length', type: 'raw' },
            { key: 'pier_breadth', type: 'raw' },
          ]
        },
        bearings: {
          properties: [
            { key: 'bearing_body_type', type: 'syscode' },
            { key: 'bearing_seat_type', type: 'syscode' },
            { key: 'bridge_fall_prevention_device_type', type: 'syscode' },
            { key: 'bearing_body_type_specify', type: 'raw' },
            { key: 'bearing_seat_type_specify', type: 'raw' },
            { key: 'bridge_fall_prevention_device_type_specify', type: 'raw' },
          ]
        },
        bridge_facilities: {
          properties: [
            { key: 'existence_of_expansion_joint', type: 'yesno' },
            { key: 'type_of_expansion_joint', type: 'syscode' },
            { key: 'type_of_expansion_joint_specify', type: 'raw' },

            { key: 'existence_of_railing_guardfence', type: 'yesno' },
            { key: 'type_of_railing_guardfence', type: 'syscode' },
            { key: 'type_of_railing_guardfence_specify', type: 'raw' },

            { key: 'existence_of_noise_barrier', type: 'yesno' },
            { key: 'type_of_noise_barrier', type: 'syscode' },
            { key: 'type_of_noise_barrier_specify', type: 'raw' },

            { key: 'existence_of_lights_traffic_signs', type: 'yesno' },
            { key: 'type_of_lighting', type: 'syscode' },
            { key: 'type_of_lighting_specify', type: 'raw' },

            { key: 'existence_of_wheel_guard', type: 'yesno' },
            { key: 'type_of_wheelguard', type: 'syscode' },
            { key: 'type_of_wheelguard_specify', type: 'raw' },

            { key: 'existence_of_drainage_facility', type: 'yesno' },
            { key: 'type_of_drainage_facility', type: 'syscode' },
            { key: 'type_of_drainage_facility_specify', type: 'raw' },

            { key: 'existence_of_wing_wall', type: 'yesno' },
            { key: 'type_of_wing_wall', type: 'syscode' },
            { key: 'type_of_wing_wall_specify', type: 'raw' },

            { key: 'existence_of_inspection_facility', type: 'yesno' },
            { key: 'type_of_inspection_facility', type: 'syscode' },
            { key: 'type_of_inspection_facility_specify', type: 'raw' },

            { key: 'existence_of_accessories', type: 'yesno' },
            { key: 'type_of_accessories', type: 'syscode' },
            { key: 'type_of_accessories_specify', type: 'raw' },

            { key: 'existence_of_footpath', type: 'yesno' },
            { key: 'type_of_footpath', type: 'syscode' },
            { key: 'existence_of_detour', type: 'raw' },
            { key: 'type_of_pavement', type: 'syscode' },
            { key: 'type_of_pavement_specify', type: 'raw' },
          ]
        },
        data_information: {
          properties: [
            { key: 'inventory_data_status', type: 'raw' },
            { key: 'latest_update_date', type: 'raw' },
            { key: 'latest_update_user', type: 'raw' },
          ]
        }
      },
    };
  },
  emits: ["updateBridge"],
  computed: {
    bridgeId() {
      return this.$route.params.id;
    },
    bridgeDataConfig() {
      const bridgeDataConfig = this.bridgeDataConfigBase;

      this.sections.forEach(section => {
        bridgeDataConfig[section.key].title = {
          key: section.key,
          label: this.$t(section.key)
        };
        bridgeDataConfig[section.key].properties = bridgeDataConfig[section.key].properties.map(p => ({
          ...p,
          label: this.$t(p.key)
        }));
      });

      return bridgeDataConfig;
    },
  },
  methods: {
    async loadBridge(bridgeId) {
      this.bridge = (await BridgeService.get(bridgeId ? bridgeId : this.bridgeId)).data;
      if (this.bridge && (this.bridge.bridge_province && !this.$store.state.authentication.provinces.includes(this.bridge.bridge_province))) {
        router.push("/home");
      }
    },
  },
  async mounted() {
    this.loadBridge();
  },
};
</script>
