<template>
  <v-container class="py-15 px-6" fluid>
    <BridgeDetailMenu
      class="mb-5"
      style="background: transparent; postion: absolute; left: 4%; top: 16.5%"
    ></BridgeDetailMenu>
    <v-card class="p-5">
      <v-row>
        <h4><u>BRIDGE ON MAP</u></h4>
        <br />
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import BridgeDetailMenu from "@/components/bridgeManagement/bridge/BridgeDetailMenu.vue";

export default {
  name: "bridge-on-map",
  components: {
    BridgeDetailMenu,
  },
};
</script>
<style scoped>
.v-btn--active {
  background-color: rgb(0, 26, 255);
  color: white;
}
</style>