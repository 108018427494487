<template class="ma-5">
  <v-container fluid class="d-flex">
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <u v-if="create">Add repair:</u>
          <u v-else>Edit repair</u>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-4">
            <v-col>
              <v-text-field :label="$t('span')" v-model="newRepairHistory.span_number"></v-text-field>
            </v-col>
            <v-col>
              <v-select v-model="newRepairHistory.component" :items="sysComponents" :item-text="description"
                item-value="code" :label="$t('component')"></v-select>
            </v-col>
            <v-col>
              <v-select v-model="newRepairHistory.element" :disabled="!newRepairHistory.component"
                :items="filteredElements" :item-text="description" item-value="code" :label="$t('element')"></v-select>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-text-field :label="$t('repair-year')" v-model="newRepairHistory.repair_year"
                type="number"></v-text-field>
            </v-col>
            <v-col>
              <v-select v-model="newRepairHistory.element_material" :items="sysElementMaterials" :item-text="description"
                item-value="code" :label="$t('element-material')"></v-select>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-select v-model="newRepairHistory.repair_type" :items="sysRepairTypes" :item-text="description"
                item-value="code" :label="$t('repair_type')"></v-select>
            </v-col>
            <v-col>
              <v-select v-model="newRepairHistory.repair_method_type" :items="sysRepairMethodTypes"
                :item-text="description" item-value="code" :label="$t('repair_method_type')"></v-select>
            </v-col>
            <v-col>
              <v-text-field :label="$t('repair-cost')" v-model="newRepairHistory.repair_cost"
                type="number"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-text-field :label="$t('repair-description')"
                v-model="newRepairHistory.repair_description"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field :label="$t('repair-note')" v-model="newRepairHistory.repair_note"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-right">
            <v-col>
              <v-btn color="red" @click="dialog = false" class="pa-2 mx-2">
                {{ $t('Close') }}
              </v-btn>
              <v-btn color="green" @click="saveRepair" :disabled="!canSaveRepair" class="pa-2 mx-2">
                {{ $t('Save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout child-flex>
      <v-card>
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" dense :headers="headers" :items="repair_list" :items-per-page="15" sort-by="id" :sort-desc="true"
          class="elevation-1 outlined" @clik:row="showRepairDetail">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Repair list") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="green" dark class="mb-2 text-right" @click="create = true; dialog = true"
                v-if="$store.getters.get_access('module_bridge_management', 2)"><v-icon small
                  class="pr-2">mdi-plus</v-icon>
                New repair
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showRepairDetail(item)"
                  v-if="$store.getters.get_access('module_bridge_management', 2)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>View details</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="deleteItem(item.id)"
                  v-if="$store.getters.get_access('module_bridge_management', 2)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <!-- </template> -->
        </v-data-table>
      </v-card>
    </v-layout>
    <AreYouSureModal resource="repair-history" :id="toDeleteId" :dialog="deleteDialog" @toggle="toggleModal"
      @deleted="deletedRepair" />
  </v-container>
</template>



<script>
import AreYouSureModal from "@/components/main/AreYouSureModal.vue";
import BridgeService from "@/services/BridgeService";
import { mapState } from "vuex";

export default {
  name: "bridge-repair-history",
  components: {
    AreYouSureModal,
  },
  data() {
    return {
      repair_list: [],
      newRepairHistory: {
        id: null,
        component: null,
        element: null,
        repair_cost: null,
        repair_description: "",
        repair_method_type: "",
        repair_note: "",
        repair_type: "",
        repair_year: null,
        element_material: null,
        span_number: null,
      },
      dialog: false,
      create: false,
      deleteDialog: false,
      toDeleteId: null,
    };
  },
  computed: {
    bridgeId() {
      return this.$route.params.id;
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    ...mapState("sysCode", ["sysComponents", "sysElements", "sysCodes"]),
    sysRepairTypes() {
      return this.sysCodes.filter((s) => s.tbl_name === 'repair_history' && s.fld_name === "repair_type");
    },
    sysRepairMethodTypes() {
      return this.sysCodes.filter((s) => s.tbl_name === 'repair_history' && s.fld_name === "repair_method_type");
    },
    sysElementMaterials() {
      return this.sysCodes.filter((s) => s.tbl_name === 'element_damage' && s.fld_name === "element_material");
    },
    filteredElements() {
      if (this.newRepairHistory.component) {
        return this.sysElements.filter(
          (e) => e.component === this.newRepairHistory.component
        );
      }
      return [];
    },
    canSaveRepair() {
      return (
        this.newRepairHistory.component &&
        this.newRepairHistory.element &&
        this.newRepairHistory.span_number &&
        this.newRepairHistory.repair_year &&
        this.newRepairHistory.element_material
      );
    },
    headers() {
      return [
        {
          text: this.$t("span"),
          value: "span_number",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("component"),
          value: `SysComponent.${this.description}`,
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("element"),
          value: `SysElement.${this.description}`,
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "repair_year",
          value: "repair_year",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "element_material",
          value: "element_material",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "repair_type",
          value: "repair_type",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "repair_method_type",
          value: "repair_method_type",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "repair_cost",
          value: "repair_cost",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "repair_description",
          value: "repair_description",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: "repair_note",
          value: "repair_note",
          sortby: true,
          color: "blue",
          align: "center",
        },

        this.$store.getters.get_access('module_bridge_management', 2) ? { text: "ACTIONS", value: "actions", color: "blue", align: "center" } : {},
      ];
    },
  },
  watch: {
    bridgeId: {
      handler(id) {
        this.getBridgeHistory();
      },
      immediate: true,
    },
    dialog: function (val) {
      if (!val) {
        this.create = false;
        this.newRepairHistory = {
          component: null,
          element: "",
          repair_cost: null,
          repair_description: "",
          repair_method_type: "",
          repair_note: "",
          repair_type: "",
          repair_year: null,
          element_material: null,
          span_number: null,
        };
      }
    },
  },
  methods: {
    async getBridgeHistory() {
      this.repair_list = (
        await BridgeService.getBridgeHistory(this.bridgeId)
      ).data;
    },
    async showRepairDetail(item) {
      this.newRepairHistory = { ...item };
      this.dialog = true;
    },
    async saveRepair() {
      await BridgeService.saveRepair({
        ...this.newRepairHistory,
        bridge_id: this.bridgeId,
      });

      await this.getBridgeHistory();
      this.dialog = false;
    },
    toggleModal() {
      this.deleteDialog = !this.deleteDialog;
    },
    async deletedRepair() {
      await this.getBridgeHistory();
    },
    deleteItem(id) {
      this.toDeleteId = id;
      this.deleteDialog = true;
    }
  },
};
</script>
<style scoped>
.v-btn--active {
  background-color: rgb(0, 26, 255);
  color: white;
}
</style>