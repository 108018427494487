<template>
  <v-container class="mt-16" fluid fill-height>
    <v-card width="100vw">
      <v-card-text>
        <v-container>
          <v-form @submit.prevent="SaveBridge">
            <h6 class="text-h6 blue--text my2">
              {{ $t("identification") }}
            </h6>
            <v-row>
              <v-col align-self="start" cols="10" sm="4" md="6">
                <table>
                  <tr>
                    <td>{{ $t("roadnumber") }}</td>
                    <td>{{ editedItem.roadnumber }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("chainage") }}</td>
                    <td>{{ editedItem.chainage }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("direction") }}</td>
                    <td>
                      {{ displayDetailIfExists("direction") }}
                    </td>
                  </tr>

                </table>
              </v-col>
              <v-col align-self="start" cols="6" sm="4" md="6">
                <table>

                </table>
              </v-col>
            </v-row>
            <v-card class="mb-4 pa-2" v-for="section in sections.slice(1, 3)" :key="section.key">
              <h3 class="my-2">
                {{ bridgeDataConfig[section.key].title.label }}
              </h3>
              <v-row>
                <v-col align-self="start" cols="6" sm="4" md="6">
                  <div v-for="prop in getFirstColumnProperties(section.key, section.split)" :key="prop.key" class="my-2">
                    <v-text-field hide-details v-if="prop.type === 'raw'" v-model="editedItem[prop.key]"
                      :label="$t(prop.key)" />
                    <v-select hide-details v-if="prop.type === 'syscode'" v-model="editedItem[prop.key]"
                      :label="$t(prop.key)" :items="getSyscodes(prop.key)" :item-text="description" item-value="code" />
                    <v-select hide-details v-if="prop.type === 'region'" v-model="editedItem[prop.key]"
                      :label="$t(prop.key)" :items="getRegionList(prop.key)" :item-text="description" item-value="id" />
                  </div>
                </v-col>
                <v-col align-self="start" cols="6" sm="4" md="6">
                  <div v-for="prop in getSecondColumnProperties(section.key, section.split)" :key="prop.key" class="my-2">
                    <v-text-field hide-details v-if="prop.type === 'raw'" v-model="editedItem[prop.key]"
                      :label="$t(prop.key)" />
                    <v-select hide-details v-if="prop.type === 'syscode'" v-model="editedItem[prop.key]"
                      :label="$t(prop.key)" :items="getSyscodes(prop.key)" :item-text="description" item-value="code" />
                    <v-select hide-details v-if="prop.type === 'region'" v-model="editedItem[prop.key]"
                      :label="$t(prop.key)" :items="getRegionList(prop.key)" :item-text="description" item-value="id" />

                  </div>
                </v-col>
              </v-row>
            </v-card>

            <v-divider />
            <v-card class="mb-4 pa-2">
              <h3 class="my-2">{{ $t("substructure") }}</h3>
              <v-row dense>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field v-model="editedItem.substructure_height" :label="$t('substructure_height')"
                    type="number" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field v-model="editedItem.pier_number_of_columns" :label="$t('pier_number_of_columns')"
                    type="number" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="4" md="3">
                  <v-select v-model="editedItem.abutment_type" :label="$t('abutment_type')"
                    :items="getSyscodes('abutment_type')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.abutment_type" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-select v-model="editedItem.foundation_type" :label="$t('foundation_type')"
                    :items="getSyscodes('foundation_type')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.foundation_type" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-select v-model="editedItem.foundation_material_type" :label="$t('foundation_material_type')"
                    :items="getSyscodes('foundation_material_type')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.foundation_material_type" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field clearable v-if="editedItem.abutment_type == 'OTHER'"
                    v-model="editedItem.abutment_type_specify" :label="$t('abutment_type')" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field clearable v-if="editedItem.foundation_type == 'OTHER'"
                    v-model="editedItem.foundation_type_specify" :label="$t('foundation_type_specify')" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field clearable v-if="editedItem.foundation_material_type == 'OTHER'"
                    v-model="editedItem.foundation_material_type_specify"
                    :label="$t('foundation_material_type_specify')" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field v-model="editedItem.nb_of_pier" :label="$t('nb_of_pier')" type="number" />
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="editedItem.nb_of_spans > 1">
                  <v-select v-model="editedItem.pier_structure" :label="$t('pier_structure')"
                    :items="getSyscodes('pier_structure')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.pier_structure" />
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="editedItem.nb_of_spans > 1">
                  <v-select v-model="editedItem.pier_shape" :label="$t('pier_shape')" :items="getSyscodes('pier_shape')"
                    :item-text="description" item-value="code" data-vv-name="editedItem.pier_shape" />
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="editedItem.pier_shape === 'CIRCL' && editedItem.nb_of_spans > 1">
                  <v-text-field v-model="editedItem.pier_diameter" :label="$t('pier_diameter')" type="number" />
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="editedItem.pier_shape === 'RECTA' && editedItem.nb_of_spans > 1">
                  <v-text-field v-model="editedItem.pier_length" :label="$t('pier_length')" type="number" />
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="editedItem.pier_shape === 'RECTA' && editedItem.nb_of_spans > 1">
                  <v-text-field v-model="editedItem.pier_breadth" :label="$t('pier_breadth')" type="number" />
                </v-col>
              </v-row>
              <v-divider />
            </v-card>
            <v-card class="mb-4 pa-2">
              <h3 class="my-2">{{ $t("bearings") }}</h3>
              <v-row dense>
                <v-col cols="12" sm="4" md="3">
                  <v-select v-model="editedItem.bearing_body_type" :label="$t('bearing_body_type')"
                    :items="getSyscodes('bearing_body_type')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.bearing_body_type" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-select v-model="editedItem.bearing_seat_type" :label="$t('bearing_seat_type')"
                    :items="getSyscodes('bearing_seat_type')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.bearing_seat_type" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-select v-model="editedItem.bridge_fall_prevention_device_type"
                    :label="$t('bridge_fall_prevention_device_type')"
                    :items="getSyscodes('bridge_fall_prevention_device_type')" :item-text="description" item-value="code"
                    data-vv-name="editedItem.bridge_fall_prevention_device_type" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field clearable v-if="editedItem.bearing_body_type == 'OTHER'"
                    v-model="editedItem.bearing_body_type_specify" :label="$t('bearing_body_type_specify')" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field clearable v-if="editedItem.bearing_seat_type == 'OTHER'"
                    v-model="editedItem.bearing_seat_type_specify" :label="$t('bearing_seat_type_specify')" />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field clearable v-if="editedItem.bridge_fall_prevention_device_type == 'OTHER'"
                    v-model="editedItem.bridge_fall_prevention_device_type_specify"
                    :label="$t('bridge_fall_prevention_device_type_specify')" />
                </v-col>
              </v-row>
            </v-card>
            <v-divider />

            <v-card class="mb-4 pa-2">
              <h3 class="my-2">
                {{ $t("bridge-facilities") }}
              </h3>
              <v-container fluid>

                <v-row dense v-for="i in 9" :key="i" class="my-2">
                  <v-col cols="12" sm="4" md="2">
                    <v-checkbox v-model="editedItem[bridgeDataConfig.bridge_facilities.properties[3 * (i - 1)].key]"
                      :label="bridgeDataConfig.bridge_facilities.properties[3 * (i - 1)].label" type="checkbox" />
                  </v-col>

                  <v-col cols="12" sm="4" md="3">
                    <v-select v-if="editedItem[bridgeDataConfig.bridge_facilities.properties[3 * (i - 1)].key]"
                      v-model="editedItem[bridgeDataConfig.bridge_facilities.properties[3 * (i - 1) + 1].key]"
                      :label="bridgeDataConfig.bridge_facilities.properties[3 * (i - 1) + 1].label"
                      :items="getSyscodes(bridgeDataConfig.bridge_facilities.properties[3 * (i - 1) + 1].key)"
                      :item-text="description" item-value="code" clearable />

                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field clearable v-if="editedItem[bridgeDataConfig.bridge_facilities.properties[3 * (i - 1) + 1].key] === 'OTHER' &&
                      editedItem[bridgeDataConfig.bridge_facilities.properties[3 * (i - 1)].key]
                      " v-model="editedItem[bridgeDataConfig.bridge_facilities.properties[3 * (i - 1) + 2].key]"
                      :label="bridgeDataConfig.bridge_facilities.properties[3 * (i - 1) + 2].label" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="2">
                    <v-checkbox v-model="editedItem.existence_of_detour" :label="$t('existence_of_detour')"
                      type="checkbox" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="2">
                    <v-checkbox v-model="editedItem.existence_of_footpath" :label="$t('existence_of_footpath')"
                      type="checkbox" />
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <v-select v-if="editedItem.existence_of_footpath" v-model="editedItem.type_of_footpath"
                      :label="$t('type-of-footpath')" :items="getSyscodes('type_of_footpath')" :item-text="description"
                      item-value="code" data-vv-name="editedItem.type_of_footpath" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="3">
                    <v-select v-model="editedItem.type_of_pavement" :label="$t('type_of_pavement')"
                      :items="getSyscodes('type_of_pavement')" :item-text="description" item-value="code"
                      data-vv-name="editedItem.type_of_pavement" />
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-if="editedItem.type_of_pavement == 'OTHER'"
                      v-model="editedItem.type_of_pavement_specify" :label="$t('type_of_pavement_specify')" clearable />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-card class="mb-4 pa-2">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12" sm="4" md="9">
                    <v-select v-model="editedItem.inventory_data_status" :label="$t('inventory_data_status')"
                      :items="dataStatusList" :item-text="description" item-value="code" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="9">
                    <v-textarea v-model="editedItem.note" :label="$t('notes')" rows="2" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-divider />
            <v-card-actions>
              <v-toolbar color="blue-grey lighten-5" rounded>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" color="transparent" rounded type="submit"><v-icon
                    color="green">mdi-check</v-icon></v-btn>
                <v-btn class="mr-4" color="transparent" rounded @click="CancelBridge"><v-icon
                    color="red">mdi-cancel</v-icon></v-btn>
              </v-toolbar>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <success-error-modal :dialog="dialog" :success="success" :errorData="errorData"
      @toggleModal="toggleModal"></success-error-modal>
  </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { } from "vee-validate/dist/rules";
import { setInteractionMode } from "vee-validate";
import BridgeService from "@/services/BridgeService";
import SuccessErrorModal from "@/components/main/SuccessErrorModal.vue";
import StaticDataService from "@/services/StaticDataService.js";

export default {
  name: "BridgeInventoryEdit",
  components: {
    SuccessErrorModal,
  },
  props: ["bridge", "bridgeDataConfig", "sections"],

  data: () => ({
    panel: [0],
    dialogInventoryDetail: false,
    valid: false,
    editedIndex: 1,
    dataStatusList: [],
    editedItem: {},
    dialog: false,
    success: false,
    errorData: "",
  }),
  computed: {
    ...mapState("adm", [
      "provincesList",
      "districtList",
    ]),
    ...mapState("sysCode", ["sysCodes"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Bridge" : "Edit Bridge";
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    districtListFiltered() {
      if (!this.editedItem.bridge_province) {
        return this.districtList;
      }
      return this.districtList.filter((district) => district.id.substr(0, 2) == this.editedItem.bridge_province);
    }
  },
  watch: {
    dialogInventoryDetail(val) {
      val || this.close();
    },
  },
  methods: {
    getRegionList(type) {
      return type === 'bridge_province' ? this.provincesList : this.districtListFiltered;
    },
    getFirstColumnProperties(section, split) {
      return this.bridgeDataConfig[section].properties.slice(0, split);
    },
    getSecondColumnProperties(section, split) {
      return this.bridgeDataConfig[section].properties.slice(split);
    },
    displayDetailIfExists(x_attribute) {
      if (!this.editedItem[x_attribute]) {
        return '';
      }
      const sysCode = this.$store.state.sysCode.sysCodes.filter((x) => x.tbl_name == 'bridge' && x.fld_name === x_attribute && x.code === this.editedItem[x_attribute]);
      if (sysCode.length > 0) {
        return sysCode[0][this.description];
      }
      return this.editedItem[x_attribute];
    },
    ...mapActions("bridge", ["addBridge", "saveDetailBridge"]),
    goBack() {
      this.$router.back();
    },
    getSyscodes(fld_name) {
      return this.sysCodes.filter(
        (x) => x.tbl_name === 'bridge' && x.fld_name === fld_name
      );
    },
    close() {
      this.dialogInventoryDetail = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = 1;
      });
      this.$router.push({
        name: "bridge-inventory",
        params: { id: this.editedItem.id, item: this.editedItem },
      });
    },
    CancelBridge() {
      this.goBack();
    },
    async SaveBridge() {
      let resp;
      this.editedItem.pier_diameter = this.editedItem.pier_shape === 'CIRCL' ? this.editedItem.pier_diameter : null;
      this.editedItem.pier_length = this.editedItem.pier_shape === 'RECTA' ? this.editedItem.pier_length : null;
      this.editedItem.pier_breadth = this.editedItem.pier_shape === 'RECTA' ? this.editedItem.pier_breadth : null;


      this.editedItem.type_of_expansion_joint = this.editedItem.existence_of_expansion_joint === true ? this.editedItem.type_of_expansion_joint : null;
      this.editedItem.type_of_railing_guardfence = this.editedItem.existence_of_railing_guardfence === true ? this.editedItem.type_of_railing_guardfence : null;
      this.editedItem.type_of_noise_barrier = this.editedItem.existence_of_noise_barrier === true ? this.editedItem.type_of_noise_barrier : null;
      this.editedItem.type_of_lighting = this.editedItem.existence_of_lights_traffic_signs === true ? this.editedItem.type_of_lighting : null;
      this.editedItem.type_of_wheelguard = this.editedItem.existence_of_wheel_guard === true ? this.editedItem.type_of_wheelguard : null;
      this.editedItem.type_of_drainage_facility = this.editedItem.existence_of_drainage_facility === true ? this.editedItem.type_of_drainage_facility : null;
      this.editedItem.type_of_wing_wall = this.editedItem.existence_of_wing_wall === true ? this.editedItem.type_of_wing_wall : null;
      this.editedItem.type_of_inspection_facility = this.editedItem.existence_of_inspection_facility === true ? this.editedItem.type_of_inspection_facility : null;
      this.editedItem.type_of_accessories = this.editedItem.existence_of_accessories === true ? this.editedItem.type_of_accessories : null;
      this.editedItem.type_of_footpath = this.editedItem.existence_of_footpath === true ? this.editedItem.type_of_footpath : null;

      this.editedItem.type_of_expansion_joint_specify = this.editedItem.existence_of_expansion_joint === true ? this.editedItem.type_of_expansion_joint_specify : null;
      this.editedItem.type_of_railing_guardfence_specify = this.editedItem.existence_of_railing_guardfence === true ? this.editedItem.type_of_railing_guardfence_specify : null;
      this.editedItem.type_of_noise_barrier_specify = this.editedItem.existence_of_noise_barrier === true ? this.editedItem.type_of_noise_barrier_specify : null;
      this.editedItem.type_of_lighting_specify = this.editedItem.existence_of_lights_traffic_signs === true ? this.editedItem.type_of_lighting_specify : null;
      this.editedItem.type_of_wheelguard_specify = this.editedItem.existence_of_wheel_guard === true ? this.editedItem.type_of_wheelguard_specify : null;
      this.editedItem.type_of_drainage_facility_specify = this.editedItem.existence_of_drainage_facility === true ? this.editedItem.type_of_drainage_facility_specify : null;
      this.editedItem.type_of_wing_wall_specify = this.editedItem.existence_of_wing_wall === true ? this.editedItem.type_of_wing_wall_specify : null;
      this.editedItem.type_of_inspection_facility_specify = this.editedItem.existence_of_inspection_facility === true ? this.editedItem.type_of_inspection_facility_specify : null;
      this.editedItem.type_of_accessories_specify = this.editedItem.existence_of_accessories === true ? this.editedItem.type_of_accessories_specify : null;


      this.editedItem.latest_update_date = new Date();
      this.editedItem.latest_update_user = this.$store.state.authentication.user.username;
      this.editedItem.nb_of_pier = this.editedItem.nb_of_spans - 1;

      try {
        resp = await BridgeService.saveBridge(this.editedItem);
        this.editedItem = resp.data;
        this.success = true;
        this.dialog = true;
        this.$emit("updateBridge", this.editedItem.id);
      } catch (err) {
        this.errorData = err.response.data.error;
        this.success = false;
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = false;
        if (this.success) {
          this.$router.push({
            name: "bridge-inventory",
            params: { id: this.editedItem.id },
          });
        }
      }
    },
  },
  created() {
    setInteractionMode("eager");
  },
  async mounted() {
    if (typeof this.$route.params.bridge === "undefined") {
      const bridgeData = (await BridgeService.get(this.$route.params.id)).data;
      try {
        const resp = await BridgeService.get(bridgeData.id);
        this.editedItem = resp.data;
      } catch (err) {
        console.log("An error occured while fetching data");
      }
    } else {
      this.editedItem = { ...this.$route.params.bridge };
    }
    this.dataStatusList = (await StaticDataService.index('inventory_data_status')).data;

  },
};
</script>

<style scoped>
table {
  background-color: #f0e6e6;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid rgb(227, 233, 233);
  width: 100%;
}

td,
th {
  width: 80px;
  border: 1px solid rgb(188, 194, 194);
  background-color: rgba(227, 233, 233);
  text-align: left;
  padding: 4px;
}

tr:nth-child(even) {
  background-color: #f7f5f5;
}
</style>