<template>
  <div class="bridge-toolbar d-flex align-center elevation-1 pa-4">
    <div>
      <span class="text-h6 mr-4" :style="{ minWidth: '250px' }">
        {{ $t("Bridge") }}:{{ bridge.roadnumber }} | {{ bridge.chainage }} |
        {{ bridge.bridge_name }}</span>
    </div>
    <div v-if="bridge.id">
      <v-btn class="ma-2" small outlined color="blue" :to="{
        name: 'bridge-inventory',
        params: { id: bridge.id, bridge: bridge },
      }">{{ $t("inventory1") }}</v-btn>
      <v-btn class="ma-2" small outlined color="blue" :to="{
        name: 'bridge-gallery',
        params: { id: bridge.id },
      }">{{ $t("gallery") }}</v-btn>
      <v-btn class="ma-2" small outlined color="blue" :to="{
        name: 'bridge-files',
        params: { id: bridge.id },
      }">{{ $t("files") }}</v-btn>
      <v-btn class="ma-2" small outlined color="blue" :to="{
        name: 'bridge-inspection',
        params: { id: bridge.id, bridge: bridge },
      }">{{ $t("inspection") }}</v-btn>
      <v-btn class="ma-2" small outlined color="blue" :to="{
        name: 'bridge-repair-history',
        params: { id: bridge.id, bridge: bridge },
      }">{{ $t("repair-history") }}</v-btn>

      <v-btn class="ma-2" small outlined color="blue" :to="{
        name: 'bridge-soundness',
        params: { id: bridge.id, bridge: bridge },
      }">{{ $t("soundness") }}</v-btn>
    </div>
  </div>
</template>





<script>
export default {
  name: "BridgeDetailMenu",
  props: ["bridge"],
};
</script>
