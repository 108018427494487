<template>
  <v-container fluid v-if="scenarios.length">
    <div class="d-flex flex-column">
      <v-card class="pa-2">
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <v-btn color="green" @click="computeBaseline" :loading="computeLoading">
              {{ $t("Re-compute") }}
            </v-btn>
          </v-card-title>
        </v-row>
      </v-card>
      <v-card class="pa-2">
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <u>{{ $t("Scenarios") }}:</u>
          </v-card-title>
          <div class="d-flex align-center flex-wrap">
            <v-btn v-for="scenario in scenarios" :key="scenario.scenario_number" v-show="scenario.scenario_number != 7" class="pa-2 ml-4 my-1" :color="selectedScenario.scenario_number == scenario.scenario_number
              ? 'green'
              : 'grey'
              " small rounded @click="selectedScenario = scenario">{{ scenario.scenario_number }} -
              {{ scenario[sysCodeLanguage] }}</v-btn>
          </div>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <u>{{ $t("Spans") }}:</u>
          </v-card-title>
          <div class="d-flex align-center flex-wrap">
            <v-btn v-for="span in bridgeElements" :key="span.span_number" class="pa-2 ml-4 my-1" :color="selectedSpanNumber === span.span_number ? 'green' : 'grey'
              " small rounded @click="selectedSpanNumber = span.span_number">{{ span.span_number }}</v-btn>
          </div>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <u>{{ $t("Components") }}:</u>
          </v-card-title>
          <div class="d-flex align-center flex-wrap">
            <v-btn v-for="component in components" :key="component.component" class="pa-2 ml-4 my-1" :color="selectedComponent === component.component ? 'green' : 'grey'
              " small rounded @click="selectedComponent = component.component">{{
    component.SysComponent[sysCodeLanguage] }}</v-btn>
          </div>
        </v-row>
      </v-card>

      <v-card class="pa-2" v-if="!computeLoading">
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <u>{{ $t("Elements") }}:</u>
          </v-card-title>
          <div class="d-flex align-center flex-wrap">
            <v-btn v-for="element in elements" :key="element.element" class="pa-2 ml-4 my-1"
              :color="selectedElement === element.element ? 'green' : 'grey'" small rounded
              @click="selectedElement = element.element">{{ element.SysElement[sysCodeLanguage] }}</v-btn>
          </div>
        </v-row>
        <SoundnessVisualization :bridge-id="bridgeId" :selected-scenario="selectedScenario"
          :selected-span-number="selectedSpanNumber" :selected-component="selectedComponent"
          :selected-element="selectedElement" granularity="element" />
      </v-card>

      <v-card class="pa-2" v-if="!computeLoading">
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <u>{{ $t("Components") }}:</u>
          </v-card-title>
          <div class="d-flex align-center flex-wrap">
            <v-btn v-for="component in components" :key="component.component" class="pa-2 ml-4 my-1" :color="selectedComponent === component.component ? 'green' : 'grey'
              " small rounded @click="selectedComponent = component.component">{{
    component.SysComponent[sysCodeLanguage] }}</v-btn>
          </div>
        </v-row>
        <SoundnessVisualization :bridge-id="bridgeId" :selected-scenario="selectedScenario"
          :selected-span-number="selectedSpanNumber" :selected-component="selectedComponent" granularity="component" />
      </v-card>

      <v-card class="pa-2" v-if="!computeLoading">
        <v-row dense class="d-flex align-center">
          <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
            <u>{{ $t("Spans") }}:</u>
          </v-card-title>
          <div class="d-flex align-center flex-wrap">
            <v-btn v-for="span in bridgeElements" :key="span.span_number" class="pa-2 ml-4 my-1" :color="selectedSpanNumber === span.span_number ? 'green' : 'grey'
              " small rounded @click="selectedSpanNumber = span.span_number">{{ span.span_number }}</v-btn>
          </div>
        </v-row>
        <SoundnessVisualization :bridge-id="bridgeId" :selected-scenario="selectedScenario"
          :selected-span-number="selectedSpanNumber" granularity="span" />
      </v-card>

      <v-card class="pa-2" v-if="!computeLoading">
        <v-card-title class="d-flex align-center mr-6 justify-space-between full-width">
          <u>{{ $t("Bridge") }}:</u>
        </v-card-title>
        <SoundnessVisualization :bridge-id="bridgeId" :selected-scenario="selectedScenario" granularity="bridge" />
      </v-card>
    </div>
  </v-container>
</template>


<script>
import SoundnessVisualization from "@/components/bridgeManagement/bridge/SoundnessVisualization.vue";
import store from "@/store";
import BridgeService from "@/services/BridgeService";
import ComputeService from "@/services/ComputeService";

export default {
  name: "BridgeSoundness",
  components: {
    SoundnessVisualization,
  },
  data() {
    return {
      id: this.$route.params.id,
      selectedSpanNumber: null,
      selectedComponent: null,
      selectedElement: null,
      selectedScenario: {},
      bridgeElements: [],
      bridgeSpanSeries: [],
      chartOptions: {},
      computeLoading: false,
      series: [
        {
          data: [],
        },
      ],
      chartSpanOptions: {},
      seriesSpan: [
        {
          data: [],
        },
      ],
    };
  },
  props: ["bridge"],
  computed: {
    scenarios() {
      return store.state.bridge.scenarios;
    },
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    components() {
      const span = this.bridgeElements.find(
        (x) => x.span_number === this.selectedSpanNumber
      );
      if (span) {
        return span.components.sort(
          (a, b) => a.SysComponent.code_order - b.SysComponent.code_order
        );
      }
      return [];
    },
    elements() {
      const component = this.components.find(
        (x) => x.component === this.selectedComponent
      );
      if (component) {
        return component.elements.sort(
          (a, b) => a.SysElement.code_order - b.SysElement.code_order
        );
      }
      return [];
    },
    bridgeId() {
      return this.$route.params.id;
    },
  },
  watch: {
    bridgeId: {
      handler: function () {
        if (this.bridgeId) {
          this.getBridgeElements();
        }
      },
      immediate: true,
    },
    selectedSpanNumber: {
      handler: function (number) {
        this.selectedComponent = this.components[0].component;
      },
      immediate: false,
    },
    selectedComponent: {
      handler: function (number) {
        this.selectedElement = this.elements[0].element;
      },
      immediate: false,
    },
    scenarios: {
      handler(scenarios) {
        if (scenarios.length > 0) {
          this.selectedScenario = scenarios[0];
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getBridgeElements() {
      const payload = (await BridgeService.getBridgeElements(this.bridgeId))
        .data;
      this.bridgeElements = [];

      payload.forEach((e) => {
        const spanIdx = this.bridgeElements.findIndex(
          (b) => b.span_number == e.span_number
        );
        if (spanIdx === -1) {
          this.bridgeElements.push({
            span_number: e.span_number,
            components: [
              {
                component: e.component,
                SysComponent: e.SysComponent,
                elements: [
                  {
                    element: e.element,
                    SysElement: e.SysElement,
                  },
                ],
              },
            ],
          });
        } else {
          const componentIdx = this.bridgeElements[
            spanIdx
          ].components.findIndex((b) => b.component == e.component);
          if (componentIdx === -1) {
            this.bridgeElements[spanIdx].components.push({
              component: e.component,
              SysComponent: e.SysComponent,
              elements: [
                {
                  element: e.element,
                  SysElement: e.SysElement,
                },
              ],
            });
          } else {
            this.bridgeElements[spanIdx].components[componentIdx].elements.push(
              {
                element: e.element,
                SysElement: e.SysElement,
              }
            );
          }
        }
      });

      this.bridgeElements.sort((a, b) => a.span_number - b.span_number);
      this.selectedSpanNumber = 1;
    },
    async computeBaseline() {
      this.computeLoading = true;
      await ComputeService.computeBaseline();
      await this.getBridgeElements();
      this.computeLoading = false;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  border: 1px solid rgb(227, 233, 233);
  padding: 5px;
  margin-left: 20px;
}

td,
th {
  border: 1px solid rgb(188, 194, 194);
  text-align: left;
  padding: 4px;
}

tr:nth-child(even) {
  background-color: #f7f5f5;
}

.v-btn--active {
  background-color: rgb(0, 26, 255);
  color: white;
}
</style>

