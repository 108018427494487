<template>
  <v-container class="mt-4" fluid fill-height>
    <v-card width="100vw">
      <v-card-text>
        <v-toolbar fixed :src="require('@/assets/FancyBridge.png')">
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="transparent" rounded @click="SaveBridge"><v-icon color="green"
              :disabled="!valid">mdi-check</v-icon></v-btn>
          <v-btn class="mr-4" color="transparent" rounded @click="CancelBridge"><v-icon
              color="red">mdi-cancel</v-icon></v-btn>
        </v-toolbar>
        <v-container>
          <v-form ref="formInvEditInformationData" v-model="valid" lazy-validation>
            <h2 class="my-4">Identification</h2>
            <v-row dense>
              <v-col cols="12" sm="4" md="2">
                <v-text-field v-model="editedItem.roadnumber" :label="$t('header-road')" :rules="itemRules" required />
                <div v-if="!doesSelectedRoadExist">{{ $t("road-does-not-exist") }}</div>
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-text-field v-model="editedItem.linkid" :label="$t('header-link')" :rules="itemRules" required />
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-text-field v-model="editedItem.chainage" :label="$t('header-chainage')" :rules="itemRules" required />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-select v-model="editedItem.direction" :label="$t('bridge-direction')"
                  :items="$store.state.sysCode.sysCodes.filter(x => x.fld_name === 'direction')" item-text="code"
                  item-value="code" :rules="itemRules" required />
              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <success-error-modal :dialog="dialog" :success="success" :successMessage="successMessage" :errorData="errorData"
      @toggleModal="toggleModal"></success-error-modal>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import BridgeService from "@/services/BridgeService";
import SuccessErrorModal from "@/components/main/SuccessErrorModal.vue";
import CentralService from '@/services/CentralService';

export default {
  name: "BridgeEditIdentificationData",
  components: {
    SuccessErrorModal,
  },

  data: () => ({
    // name: 'ValidationObserver',
    choiceText: "",
    editedIndex: -1,
    editedItem: {
      id: 0,
      roadid: null,
      roadnumber: null,
      linkid: null,
      chainage: null,
      linkchainage: null,
      bridge_status: null,
      bridge_status_comments: null,
      bridge_name: null,
      bridge_crossing: null,
      bridge_structure: null,
      bridge_structure_specify: null,
      bridge_material: null,
      bridge_material_specify: null,
      river_name: null,
      province: "",
      bridge_gps_x: 0.0,
      bridge_gps_y: 0.0,
      bridge_gps_z: 0.0,
      nb_of_spans: null,
      bridge_length: null,
      max_span_length: null,
      min_span_length: null,
      year_of_construction: null,
      latest_repair_year: null,
      design_standard: null,
      design_structure: null,
      max_load: null,
      source_of_funding: null,
      width_of_carriageway: null,
      bridge_width: null,
      width_of_inbound_lane: null,
      width_of_outbound_lane: null,
      bridge_freeboard: null,
      superstructure_type: null,
      main_girder_material_type: null,
      nb_of_main_girders: null,
      deck_slab_material_type: null,
      superstructure_other_members_material_type: null,
      rust_proofing: null,
      pier_structure: null,
      pier_shape: null,
      type_of_footpath: null,
      type_of_wheelguard: null,
      type_of_wheelguard_specify: null,
      type_of_inspection_facility: null,

      substructure_height: null,
      nb_of_pier: null,
      pier_size: null,
      abutment_type: null,
      foundation_type: null,
      foundation_material_type: null,
      bearing_body_type: null,
      bearing_seat_type: null,
      bridge_fall_prevention_device_type: null,
      existence_of_expansion_joint: null,
      existence_of_railing_guardfence: null,
      existence_of_noise_barrier: null,
      existence_of_lights_traffic_signs: null,
      existence_of_wheel_guard: null,
      existence_of_drainage_facility: null,
      existence_of_wing_wall: null,
      existence_of_inspection_facility: null,
      existence_of_accessories: null,
      existence_of_footpath: null,
      existence_of_detour: null,
      type_of_expansion_joint: null,
      type_of_expansion_joint_specify: null,
      type_of_railing_guardfence: null,
      type_of_railing_guardfence_specify: null,
      type_of_noise_barrier: null,
      type_of_noise_barrier_specify: null,
      type_of_drainage_facility: null,
      type_of_drainage_facility_specify: null,
      type_of_pavement: null,
      type_of_pavement_specify: null,
      type_of_wing_wall: null,
      type_of_wing_wall_specify: null,
      type_of_accessories: null,
      type_of_accessories_specify: null,
      bridge_drawings: null,
      note: null,
      latest_update_date: null,
      latest_update_user: null,
      direction: null,
    },
    districtList_select: [],
    roadlist: [],
    linklist: [],
    valid: false,
    save: false,
    success: false,
    successMessage: {},
    dialog: false,
    newBridge: null,
    errorData: "",
    itemRules: [
      (v) => !!v || "Item is required",
      // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    roadNumberList: [],
  }),
  computed: {
    ...mapState("adm", [
      "provincesList",
      "districtList",
    ]),
    ...mapState("sysCode", ["sysCodes"]),
    formTitle() {
      return this.editedIndex === -1 ? this.$t("New Bridge") : this.$t("Edit Bridge");
    },
    doesSelectedRoadExist() {
      return this.roadNumberList.map(x => x.RoadNumber).includes(this.editedItem.roadnumber);
    }
  },
  methods: {
    getSyscodes(fld_name) {
      return this.sysCodes.filter(
        (x) => x.tbl_name === 'bridge' && x.fld_name === fld_name
      );
    },
    CancelBridge() {
      this.$router.back();
    },
    async SaveBridge() {
      const status = this.$refs.formInvEditInformationData.validate();
      this.editedItem.latest_update_date = new Date();
      this.editedItem.latest_update_user = this.$store.state.authentication.user.username;
      if (status == true) {
        const bridge = Object.assign({}, this.editedItem);
        let resp;
        try {
          if (bridge.id == -1 || bridge.id == null) {
            resp = await BridgeService.createBridge(bridge);
            this.save = false;
            this.successMessage = {
              en: "Bridge successfully created !",
              la: "Bridge successfully created !",
            };
          } else {
            resp = await BridgeService.saveBridge(bridge);
            this.save = true;
            this.successMessage = {
              en: "Modifications successfully saved !",
              la: "Modifications successfully saved !",
            };
          }
          this.newBridge = resp.data;
          this.success = true;
          this.dialog = true;
          this.errorData = "";
          this.$emit("updateBridge", this.newBridge.id);
        } catch (err) {
          this.errorData = err.response.data.error;
          this.success = false;
          this.dialog = true;
        }
      } else {
        return;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = false;
        if (this.success) {
          this.$router.push({
            name: "bridge-inventory",
            params: { id: this.newBridge.id },
          });
        }
      }
    },
  },
  async created() {
    let bridgeData;
    this.roadNumberList = (await CentralService.get("roads")).data.sort((a, b) => a.RoadNumber.localeCompare(b.RoadNumber));
    // this.linkIdList = (await CentralService.get("links")).data;
    if (typeof this.$route.params.bridge === "undefined") {
      bridgeData = (await BridgeService.get(this.$route.params.id)).data;
    } else {
      bridgeData = this.$route.params.bridge;
    }
    this.editedItem.linkid = bridgeData.linkid;
    this.editedIndex = this.$route.params.id;
  },
  async mounted() {
    if (
      typeof this.$route.params.bridge === "undefined" &&
      this.$route.params.id != "-1"
    ) {
      const bridgeData = (await BridgeService.get(this.$route.params.id)).data;
      try {
        const resp = await BridgeService.get(bridgeData.id);
        this.editedItem = resp.data;
      } catch (err) {
        console.log("An error occured while fetching data");
      }
    } else if (this.$route.params.id === "-1") {
      this.editedItem = {};
    } else {
      this.editedItem = this.$route.params.bridge;
    }

    this.valid = false;
  }
};
</script>

<style scoped>
.success {
  color: green;
}

.error {
  color: red;
}
</style>